import van from 'vanjs-core'

const {div, t, button, span} = van.tags

const Pagination = ({max, onBack, onForward}) => {
    let minPage = van.state(1);
    let maxPage = van.state(max < 10 ? max : 10);
    return div(
      {class: "flex w-full gap-2 pb-2 pt-2 font-rope text-base items-center"},
      t("Showing "),
      () => t({class: "font-semibold"}, `${minPage.val}-${maxPage.val}`),
      t(" of "),
      () => t({class: "font-semibold"}, `${max}`),
      div({class: "flex-grow"}),
      button(
        {class: `font-rope ${minPage.val != 1 ? "bg-white" : "bg-steel"} text-sm sm:text-md font-semibold hover:bg-steel w-10 h-10 rounded-lg  border-2 border-steel`,
        onclick: () => {
          if (minPage.val != 1){
            minPage.val = minPage.val - 10;
            maxPage.val = maxPage.val - 10;
          }
          onBack();
        }},
        span(
          {class: "material-symbols-outlined p-0"},
          "chevron_left"
        ) 
      ),
      button(
        {class: `font-rope ${maxPage.val < max ? "bg-white" : "bg-steel"} text-sm sm:text-md font-semibold hover:bg-steel w-10 h-10 rounded-lg  border-2 border-steel`,
        onclick: () => {
          if (maxPage.val < max){
            minPage.val = minPage.val + 10;
            maxPage.val = maxPage.val + 10;
          }
          onForward();
        }},
        span(
          {class: "material-symbols-outlined"},
          "chevron_right"
        ) 
      ),
    )
}

export default Pagination;