import van from "vanjs-core";
import LandingHero from "./LandingHero";
import PrimaryButton from "./components/PrimaryButton";
import { SmallLink, SmallLinkButton } from "./components/SmallLink";
import { signInUserWithGoogle, isSignedIn, globalUserExam, specifiedCaseNumber, specifiedCaseId } from "./globals";
import { ExamInvite } from "./components/ExamInvite";
import { route } from "./components/Router";

const { div, button, a, t } = van.tags;

const LandingPage = () => {
  // van.derive(() => {
  //   if (isSignedIn()) {
  //     route("/home");
  //   }
  // });

  // const initState = () => {
  //   if (isSignedIn()) {
  //     route("/home");
  //   }
  // }

  // const trigger = van.state(!isSignedIn());
  // van.derive(() => trigger.val && initState())
  // trigger.val = !trigger.val;

  return div(
    { class: "flex justify-center flex-col h-full p-5" },
    div(
      { class: "w-full align-middle items-center flex flex-col" },
      LandingHero(),
      div({ class: "h-[50px]" }),
      () => (globalUserExam?.val != null ? ExamInvite() : ""),
      () => (globalUserExam?.val != null ? div({ class: "h-[50px]" }) : ""),
      PrimaryButton({
        text: "Try a Case",
        onclick: () => {
          specifiedCaseNumber.val = 3;
          specifiedCaseId.val = "uajAXPVBc23bLmLLXxcT";
          route("/videocase");
        }
      }),
      div({ class: "h-[50px]" }),
      () =>
        !isSignedIn()
          ? div(
              { class: "flex gap-2 text-xl" },
              SmallLinkButton({
                onclick: () => signInUserWithGoogle(),
                text: "Log In",
              }),
              t("|"),
              SmallLinkButton({
                onclick: () => signInUserWithGoogle(),
                text: "Sign Up",
              }),
            )
          : "",
      div(
        {class: "flex gap-2 absolute bottom-[20px] right-[20px] sm:text-sm text-xs font-rope text-gray-400"},
        t("Version Chilly"),
      )
    ),
  );
};

export default LandingPage;
