import van from "vanjs-core";
import { Router, route, url } from "./components/Router";

const { t, button, span } = van.tags;

van.add(document.body, Router());

window.addEventListener("popstate", (e) => {
  route(document.location.pathname);
});
