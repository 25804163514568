import van from "vanjs-core";
import { route } from "./Router";
import { isSignedIn, globalUser } from "../globals";
import { SmallProfileCircle } from "./ProfileCircle";
import { ConfirmationModal } from "./ConfirmationModal";
import { SmallLink } from "./SmallLink";
const { div, a, img, t, br } = van.tags;

const NavBar = (showConfirmationFirst = false) => {
  const imageUrl = new URL("../../images/logo.png", import.meta.url);
  let showConfirmationModal = van.state(false);
  return div(
    {
      class:
        "flex w-full items-center p-5 flex-grow-0 font-semibold text-dark text-2xl absolute top-0 left-0",
    },

    () =>
      showConfirmationModal.val
        ? ConfirmationModal({
            onCancel: () => (showConfirmationModal.val = false),
            onConfirm: () => route("/"),
            label: div(
              t(
                { class: "text-dark font-semibold text-2xl font-rope" },
                "Are you sure?",
              ),
              div({ class: "h-4" }),
              t(
                { class: "text-dark font-rope font-normal text-base" },
                "Going to Home will quit the case WITHOUT SAVING.",
              ),
              br(),
              t(
                { class: "text-dark text-semibold font-rope text-base" },
                "Please Note: Your timer is not paused right now!",
              ),
              div({ class: "h-4" }),
            ),
          })
        : "",
    a(
      {
        class: "font-rope",
        href: "/",
        onclick: (e) => {
          e.preventDefault();
          if (showConfirmationFirst) {
            showConfirmationModal.val = true;
            return;
          }
          route("/");
        },
      },
      div(
        { class: "flex items-center gap-2" },
        img({ src: imageUrl, class: "w-14 h-auto" }),
        t({class: "text-2xl"}, "Casenow"),
      ),
    ),
    div({ class: "w-full flex flex-grow" }),
    () => (isSignedIn() ? SmallProfileCircle(showConfirmationFirst) : ""),
  );
};

export { NavBar };
