import van from "vanjs-core"
import PrimaryButton from "./PrimaryButton";

const {div, span, h3, a, t} = van.tags

const SideStats = ({data, styles}) => {

  let stylesBars = div(
      {class: "rounded-lg w-[300px] border-steel border-2 bg-white p-5 space-y-5"});
  
  for (let style of styles){
    van.add(
      stylesBars, 
      div(
          div({class: "mb-2 flex justify-between items-center"},
            h3({class: "text-sm font-semibold text-dark font-rope "},
              style,
            ),
            span({class: "text-sm text-dark font-rope"},
              `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == style.toLowerCase()).length} / ${data.filter(x => x.style.toLowerCase().trim() == style.toLowerCase()).length}`,
            ),
          ),
          div({class: "flex w-full h-4 bg-steel rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
            div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-dark text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == style.toLowerCase()).length / data.filter(x => x.style.toLowerCase().trim() == style.toLowerCase()).length}%`}),
          ),
        ),
    )
  }

  return div(
    {class: "w-full sm:w-[350px] flex-grow-0 space-y-4 max-h-[500px] sm:max-h-none pl-5 flex flex-col items-center md:items-start"},
    a(
      {class: "w-[300px] relative rounded-lg pl-4 pr-4 pb-2 pt-2 text-center bg-white text-thanos font-rope text-md border-2 hover:border-4  border-thanos mb-1", href: "/generate"},
      t("Generate Case"),
      span(
        {class: "absolute left-4 !text-md material-symbols-outlined !text-thanos"},
        "book_4_spark"
      )
    ),
    t({class: "font-rope text-2xl"}, "Casing Journey"),
    div(
      {class: "flex flex-col gap-4 overflow-auto"},
      div(
      {class: "rounded-lg w-[300px] border-steel border-2 bg-white p-5 space-y-5"},
        div(
          div({class: "mb-2 flex justify-between items-center"},
            h3({class: "text-sm font-semibold text-dark font-rope "},
              "Total",
            ),
            span({class: "text-sm text-dark font-rope"},
              `${data.filter(x => x.status.toLowerCase().trim() == "done").length} / ${data.length}`,
            ),
          ),
          div({class: "flex w-full h-4 bg-powder rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
            div({class: "flex flex-col justify-center rounded-full overflow-hidden  text-xs text-white text-center whitespace-nowrap transition duration-500 bg-blue-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done").length / data.length}%`}),
          ),
        ),
        div(
          div({class: "mb-2 flex justify-between items-center"},
            h3({class: "text-sm font-semibold text-dark font-rope "},
              "Easy",
            ),
            span({class: "text-sm text-dark font-rope"},
              `${data.filter(x => (x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'easy')).length} / ${data.filter(x => x.difficulty.toLowerCase().trim() == 'easy').length}`,
            ),
          ),
          div({class: "flex w-full h-4 bg-[#DEEAD7] rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
            div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-asparagus text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'easy').length / data.filter(x => x.difficulty.toLowerCase().trim() == 'easy').length}%`}),
          ),
        ),
        div(
          div({class: "mb-2 flex justify-between items-center"},
            h3({class: "text-sm font-semibold text-dark font-rope "},
              "Medium",
            ),
            span({class: "text-sm text-dark font-rope"},
              `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'medium').length} / ${data.filter(x => x.difficulty.toLowerCase().trim() == 'medium').length}`,
            ),
          ),
          div({class: "flex w-full h-4 bg-[#FDEDC3] rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
            div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-gold text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'medium').length / data.filter(x => x.difficulty.toLowerCase().trim() == 'medium').length}%`}),
          ),
        ),
        div(
          div({class: "mb-2 flex justify-between items-center"},
            h3({class: "text-sm font-semibold text-dark font-rope "},
              "Hard",
            ),
            span({class: "text-sm text-dark font-rope"},
              `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'hard').length} / ${data.filter(x => x.difficulty.toLowerCase().trim() == 'hard').length}`,
            ),
          ),
          div({class: "flex w-full h-4 bg-[#F0D1D1] rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
            div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-fire text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'hard').length / data.filter(x => x.difficulty.toLowerCase().trim() == 'hard').length}%`}),
          ),
        ),
        div(
          div({class: "mb-2 flex justify-between items-center"},
            h3({class: "text-sm font-semibold text-dark font-rope "},
              "Custom",
            ),
            span({class: "text-sm text-dark font-rope"},
              `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'hard').length} / ${data.filter(x => x.difficulty.toLowerCase().trim() == 'custom').length}`,
            ),
          ),
          div({class: "flex w-full h-4 bg-[#CBC2FF] rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
            div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-thanos text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.difficulty.toLowerCase().trim() == 'custom').length / data.filter(x => x.difficulty.toLowerCase().trim() == 'custom').length}%`}),
          ),
        ),
      ),
      stylesBars
      // div(
      // {class: "rounded-lg w-[300px] border-steel border-2 bg-white p-5 space-y-5"},
      //   div(
      //     div({class: "mb-2 flex justify-between items-center"},
      //       h3({class: "text-sm font-semibold text-dark font-rope "},
      //         "Interviewer-Led",
      //       ),
      //       span({class: "text-sm text-dark font-rope"},
      //         `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == 'interviewer-led').length} / ${data.filter(x => x.style.toLowerCase().trim() == 'interviewer-led').length}`,
      //       ),
      //     ),
      //     div({class: "flex w-full h-4 bg-steel rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
      //       div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-dark text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == 'interviewer-led').length / data.filter(x => x.style.toLowerCase().trim() == 'interviewer-led').length}%`}),
      //     ),
      //   ),
      //   div(
      //     div({class: "mb-2 flex justify-between items-center"},
      //       h3({class: "text-sm font-semibold text-dark font-rope "},
      //         "Interviewee-Led",
      //       ),
      //       span({class: "text-sm text-dark font-rope"},
      //         `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == 'interviewee-led').length} / ${data.filter(x => x.style.toLowerCase().trim() == 'interviewee-led').length}`,
      //       ),
      //     ),
      //     div({class: "flex w-full h-4 bg-steel rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
      //       div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-dark text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == 'interviewee-led').length / data.filter(x => x.style.toLowerCase().trim() == 'interviewee-led').length}%`}),
      //     ),
      //   ),
      //   div(
      //     div({class: "mb-2 flex justify-between items-center"},
      //       h3({class: "text-sm font-semibold text-dark font-rope "},
      //         "Market Sizing",
      //       ),
      //       span({class: "text-sm text-dark font-rope"},
      //         `${data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == 'market sizing').length} / ${data.filter(x => x.style.toLowerCase().trim() == 'market sizing').length}`,
      //       ),
      //     ),
      //     div({class: "flex w-full h-4 bg-steel rounded-full overflow-hidden", role: "progressbar", "aria-valuenow": "25", "aria-valuemin": "0", "aria-valuemax": "100"},
      //       div({class: "flex flex-col justify-center rounded-full overflow-hidden bg-dark text-xs text-white text-center whitespace-nowrap transition duration-500", style: `width: ${100.0 * data.filter(x => x.status.toLowerCase().trim() == "done" && x.style.toLowerCase().trim() == 'market sizing').length / data.filter(x => x.style.toLowerCase().trim() == 'market sizing').length}%`}),
      //     ),
      //   ),
      // ),
    )
    // div(
    //   {class: "flex w-full rounded-lg border-steel border-2 bg-white p-5"},
    //   span(
    //     {class: `material-symbols-outlined pr-2 text-[#DF8544]`},
    //     "local_fire_department"
    //   ),
    //   t({class: "font-rope text-base"}, "Streak"),
    //   div({class: "flex-grow w-full"}),
    //   t({class: "font-rope text-base font-semibold"}, "3"),
    // ),
    // div(
    //   {class: "flex w-full rounded-lg border-steel border-2 bg-white p-5"},
    //   span(
    //     {class: `material-symbols-outlined pr-2 text-blue-500`},
    //     "public"
    //   ),
    //   t({class: "font-rope text-base flex-grow min-w-40"}, "Global Rank"),
    //   div({class: "flex-grow w-full"}),
    //   t({class: "font-rope text-base font-semibold"}, "3/110"),
    // ),
    
  );
}

export default SideStats;