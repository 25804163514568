const formatElapsedTimeForCasing = (t) => {
  return (Math.round(t * 10) / 10).toFixed(1) + " s";
}

const formatMessageForChat = (msg) => {
  let formatted = msg.replace(/<INTERVIEWER>:/g, "").replace(/<CANDIDATE>:/g, "").replace(/<INTERVIEWER>/g, "").replace(/<CANDIDATE>/g, 
  "").replace(/END/g, "")
  if (formatted.indexOf("<IMG>") >= 0){
    formatted = formatted.substring(0, formatted.indexOf("<IMG>"))
  }
  return formatted;
}

const formatNameForProfileIcon = (name) => {
  let words = name.trim().split(" ");
  if (words.length == 0){
    return "P";
  }
  if (words.length == 1){
    return words[0];
  }
  try{
    return words[0][0] + words[1][0];
  }   
  catch(_){
    return "P";
  }
}

export {formatElapsedTimeForCasing, formatMessageForChat, formatNameForProfileIcon}