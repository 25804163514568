import van from "vanjs-core"

const {div, t, span, hr} = van.tags;

const FeedbackRow = ({criterion, score, description}) => {

  const showDescription = van.state(false);

  return div(
    {"class": "flex flex-col"},
    div(
      {class: "flex flex-row w-full"},
      div(
        {class: "w-[90%]"},
        t(
          {class: "font-rope"},
          criterion
        ),
      ),
      div({class: "flex flex-grow"}),
      div(
        {class: "flex justify-end flex-grow"},
        t(
          {class: "font-rope font-semibold text-fire flex-grow text-end min-w-16"},
          score
        ),
      ),
      div({class: "w-4"}),
      () => !showDescription.val ? span(
        {class: "material-symbols-outlined hover:cursor-pointer", onclick: () => showDescription.val = true},
        "expand_circle_down"
      ) : "",
      () => showDescription.val ? span(
        {class: "material-symbols-outlined hover:cursor-pointer", onclick: () => showDescription.val = false},
        "expand_circle_up"
      ) : "",
    ),
    () => showDescription.val ? t(
      {class: "font-rope text-sm italic"},
      description
    ) : "",
    div({class: "h-2"}),
    hr()
  )
}

export {FeedbackRow};