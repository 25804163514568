import van from "vanjs-core";
import PrimaryButton from "./components/PrimaryButton";
import { SmallLinkButton } from "./components/SmallLink";
import {
  app,
  globalUser,
  signOutUser,
  db,
  retrieveTranscriptAndProblem,
  isMember,
  globalUserExam,
  retrieveTranscript,
} from "./globals";
import { ProfileCircle } from "./components/ProfileCircle";
import { NavBar } from "./components/NavBar";
import { route } from "./components/Router";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { TranscriptModal } from "./components/TranscriptModal";
import { ExamInvite } from "./components/ExamInvite";

const { div, button, a, t, hr } = van.tags;

const ProfilePage = () => {
  let showTranscriptModal = van.state(false);
  let viewingTranscriptTitle = van.state("");
  let viewingTranscriptData = van.state({});
  let listOfCasesCompleted = div({
    class:
      "flex flex-col gap-2 pr-4 w-full sm:max-w-[700px] max-h-[300px] overflow-auto",
  });

  const initState = async () => {
    const db = await getFirestore(app);
    console.log("UID: ", globalUser?.val?.uid);
    let ref = await doc(db, "users", globalUser.val?.uid ?? "");
    let snap = await getDoc(ref);
    let proceed = snap.exists();
    let data = snap.exists() ? snap.data() : {};
    if (!snap.exists()) {
      const q = query(
        collection(db, "users"),
        where("uid", "==", globalUser.val?.uid),
      );
      let snapList = await getDocs(q);
      if (snapList.size > 0) {
        proceed = true;
        data = snapList.docs.at(0).data();
      }
    }
    if (proceed) {
      if (data?.problemsCompleted?.length > 0) {
        van.add(
          listOfCasesCompleted,
        );
        van.add(listOfCasesCompleted, hr());
      }
      for (let problemCompleted of data?.problemsCompleted ?? []) {
        let entry = div(
          { class: "flex w-full" },
          t(
            { class: "font-rope font-normal" },
            problemCompleted?.date?.toDate()?.toLocaleString() ?? "",
          ),
          div({ class: "w-4" }),
          t(
            { class: "font-rope font-normal" },
            `Score: ${problemCompleted?.score.toString() ?? "--"} / ${problemCompleted?.maxScore.toString() ?? "--"}`,
          ),
          div({ class: "flex flex-grow" }),
          SmallLinkButton({
            onclick: async () => {
              let additionalData = await retrieveTranscript(problemCompleted);
              viewingTranscriptData.val = additionalData;
              viewingTranscriptTitle.val =
                "Case #" +
                  additionalData?.problemNumber +
                  ": " +
                  additionalData?.problemTitle ?? "";
              showTranscriptModal.val = true;
            },
            text: "View Transcript",
          }),
        );
        van.add(listOfCasesCompleted, entry);
        van.add(listOfCasesCompleted, hr());
      }
    }
  }

  const trigger = van.state(true);
  van.derive(() => trigger.val && initState());
  trigger.val = false;

  return div(
    { class: "flex flex-col h-full p-5 justify-center" },
    NavBar(),
    () =>
      showTranscriptModal.val
        ? TranscriptModal({
            problemTitle: viewingTranscriptTitle.val,
            transcriptData: viewingTranscriptData.val,
            onCancel: () => (showTranscriptModal.val = false),
          })
        : "",
    div(
      { class: "flex flex-col h-[90%] sm:p-5" },
      div(
        {
          class: "w-full align-middle items-center flex flex-col font-semibold",
        },
        ProfileCircle(),
        div({ class: "h-8" }),
        t(
          { class: "text-dark font-rope text-4xl" },
          globalUser.val?.displayName ?? "Your Profile",
        ),
        div({ class: "h-4" }),
        t(
          { class: "text-dark font-rope font-normal text-xl" },
          globalUser.val?.email ?? "",
        ),
        div({ class: "h-4" }),
        div({ class: "h-4" }),
        button(
          {
            class:
              "p-2 max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope  text-white bg-dark hover:bg-fire hover:text-light",
            onclick: () => {
              signOutUser();
              route("/");
            },
          },
          "Log Out",
        ),
        div({ class: "h-8" }),
        () => (globalUserExam.val != null ? ExamInvite() : ""),
        () => (globalUserExam.val != null ? div({ class: "h-4" }) : ""),
        div(
          {class: "flex flex-col border-steel border-2 p-2 rounded-lg"},
          t({ class: "font-rope text-xl font-semibold pb-2 text-left sm:w-[700px]" }, "Cases Completed"),
         listOfCasesCompleted,
        ),
        div({ class: "h-4" }),
        PrimaryButton({
          onclick: () => {
            route("/");
          },
          text: "Home",
        }),
      ),
    ),
  );
};

export { ProfilePage };
