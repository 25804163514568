import van from "vanjs-core";
import TbdPage from "../TbdPage";
import CasePage from "../CasePage";
import HomePage from "../HomePage";
import LandingPage from "../LandingPage";
import { globalAudioStream, globalStream, globalVideoStream, isSignedIn, specifiedCaseNumber } from "../globals";
import { ProfilePage } from "../ProfilePage";
import { globalUser } from "../globals";
import NoMembershipPage from "../NoMembership";
import ExpiredPage from "../ExpiredPage";
import NoAccessPage from "../NoAccessPage";
import NotFoundPage from "../NotFoundPage";
import VideoCasePage from "../VideoCasePage";
import GenerateCasePage from "../GenerateCasePage";
import PDFViewerPage from "../PDFViewerPage";

const { t, div } = van.tags;

let url = van.state(document.location.pathname);
let blockRefresh = van.state(false);

let canInitState = van.state(true);

let urlParams = van.state([]);

const routes = {
  "/": LandingPage,
  "/case": CasePage,
  "/specific-case": CasePage,
  "/profile": ProfilePage,
  "/tbd": TbdPage,
  "/nomembership": NoMembershipPage,
  "/expired": ExpiredPage,
  "/noaccess": NoAccessPage,
  "/videocase": VideoCasePage,
  "/generate": GenerateCasePage,
};

const routeToTitle = {
  "/": "Casenow",
  "/case": "Case In Progress",
  "/profile": "Your Profile",
  "/videocase": "Case In Progress"
};

const Router = () => {
  console.log("HERE!!")
  console.log("Re routing!!!!");
  if (globalAudioStream.val){
    globalAudioStream.val.getAudioTracks().forEach(track => track.stop());
  }
  if (globalVideoStream.val){
    globalVideoStream.val.getVideoTracks().forEach(track => track.stop());
  }
  let parsed = van.derive(() => "/" + url.val.split("/")[1]);
  let secondParam = van.derive(() =>
    url.val.split("/").length > 2 ? url.val.split("/")[2] : "",
  );
  if (parsed.val == "/pdf120"){
    console.log("Got here...");
    return PDFViewerPage(secondParam.val);
  } 
  console.log(parsed.val);
  // if (parsed.val == "/"){
  //   return div({ class: "flex flex-col h-full" }, () => isSignedIn() ? HomePage() : LandingPage());
  // }
  if (parsed.val in routes) {
    // if (parsed.val == "/case" && secondParam.val != "") {
    //   specifiedCaseNumber.val = parseInt(secondParam.val);
    // }
    return div({ class: "flex flex-col h-full" }, () => {
      if (parsed.val == "/" && isSignedIn()){
        return HomePage();
      }
      return routes[parsed.val]();
    });
  }
  url.val = "/";
  return div({ class: "flex flex-col h-full" }, () => isSignedIn() ? HomePage() : LandingPage());
};

const route = (href) => {

  if (globalAudioStream.val){
    globalAudioStream.val.getAudioTracks().forEach(track => track.stop());
  }
  if (globalVideoStream.val){
    globalVideoStream.val.getVideoTracks().forEach(track => track.stop());
  }

  window.speechSynthesis.cancel();
  if (href in routeToTitle) {
    document.title = routeToTitle[href];
  }
  window.onbeforeunload = null;
  if (url.val == href) {
    return;
  }
  url.val = href;
  console.log("Changed url val ", url.val)
  window.history.pushState("page2", "Title", href);
};

export { Router, route, url, canInitState };
