import van from 'vanjs-core'

const {button, span, t} = van.tags;

const FilterChip = ({message, onRemove}) => {
  return button(
    {
      class: "border-2 border-dark rounded-full bg-white text-xs flex gap-1 p-2 items-center hover:text-fire hover:border-fire text-dark",
      onclick: onRemove
    }, 
    span(
      {class: "material-symbols-outlined !text-sm"},
      "cancel"
    ),
    t({class: "font-semibold font-rope min-w-8 text-left"}, `${message}`),
  )
}

export default FilterChip;