import van from 'vanjs-core'
import { formatNameForProfileIcon } from '../helpers/formatting';
import { globalUser, isMember } from '../globals';
import {route} from './Router';
import { ConfirmationModal } from './ConfirmationModal';

const {a, t, div, br} = van.tags;

const ProfileCircle = (showConfirmationFirst = false) => {
  let showConfirmationModal = van.state(false);
  return div( () => !showConfirmationModal.val ? a(
    {class: isMember.val ? "w-10 h-10 p-7 bg-steel border-gold text-2xl hover:cursor-pointer border-4 text-dark rounded-full font-rope flex justify-center items-center" : "w-10 h-10 p-7 bg-steel text-2xl hover:cursor-pointer border-4 text-dark rounded-full font-rope flex justify-center items-center",
      onclick: (e)=>{
        e.preventDefault(); 
        if (showConfirmationFirst){
          console.log("HERE!!")
          showConfirmationModal.val = true;
          return;
        }
        route("/profile")
      }
    },
    t(
      formatNameForProfileIcon(globalUser.val?.displayName ?? "Profile")
    )
  ) : ConfirmationModal({
    onCancel: () => showConfirmationModal.val = false,
    onConfirm: () => route("/profile"),
    label: div(
          t({class: "text-dark font-semibold text-2xl font-rope"},
          "Are you sure?",
          ),          
          div({class: "h-4"}),
          t({class: "text-dark font-rope font-normal text-base"},
            "Going to Profile will quit the case WITHOUT SAVING.",
          ),
          br(),
          t({class: "text-dark text-semibold font-rope text-base"},
            "Please Note: Your timer is not paused right now!",
          ),
          div({class: "h-4"}),
        ),
  }) )
}


const SmallProfileCircle = (showConfirmationFirst = false) => {
  let showConfirmationModal = van.state(false);
  return div( () => !showConfirmationModal.val ? a(
    {class: isMember.val ? "w-5 h-5 p-5 bg-steel border-gold text-xl hover:cursor-pointer border-4 text-dark rounded-full font-rope flex justify-center items-center" : "w-5 h-5 p-5 bg-steel text-xl hover:cursor-pointer border-4 text-dark rounded-full font-rope flex justify-center items-center",
      onclick: (e)=>{
        e.preventDefault(); 
        if (showConfirmationFirst){
          console.log("HERE!!")
          showConfirmationModal.val = true;
          return;
        }
        route("/profile")
      }
    },
    t(
      formatNameForProfileIcon(globalUser.val?.displayName ?? "Profile")
    )
  ) : ConfirmationModal({
    onCancel: () => showConfirmationModal.val = false,
    onConfirm: () => route("/profile"),
    label: div(
          t({class: "text-dark font-semibold text-2xl font-rope"},
          "Are you sure?",
          ),          
          div({class: "h-4"}),
          t({class: "text-dark font-rope font-normal text-base"},
            "Going to Profile will quit the case WITHOUT SAVING.",
          ),
          br(),
          t({class: "text-dark text-semibold font-rope text-base"},
            "Please Note: Your timer is not paused right now!",
          ),
          div({class: "h-4"}),
        ),
  }) )
}

export {ProfileCircle, SmallProfileCircle}