import van from "vanjs-core"
import {route} from "./components/Router";
import PrimaryButton from "./components/PrimaryButton";
import { NavBar } from "./components/NavBar";
import { SmallLink } from "./components/SmallLink";
import { getIdToken } from "firebase/auth";
import { globalUser } from "./globals";

const {div, button, a, t, h1, span, textarea, script} = van.tags;

const PDFViewerPage = (caseTag) => {
  let textInput = textarea(
          {class: "w-full h-[250px] sm:h-[300px] p-2 border-steel border-2 font-rope rounded-lg", placeholder: "Description of the case.\nKeeping it simple and clear will produce better results.\nYou can include a summary of the scenario, a description of a visual to include, the number of questions, or any other detail.\nThe generator will try its best to fit your requirements."}
        );

  let loading = van.state(false);
  let caseReady = van.state(false);

  let progressAmount = van.state(0);
  let progressLabel = van.state("");
  let loadingDescription = van.state("");

  let progressCheckInterval = 0;

  let caseData = van.state({});

  // let graphContainer = div({class: "flex flex-col gap-5"});
  // for (let x = 0; x < 10; ++x){
  //   van.add(
  //     graphContainer,
  //     div({class: "w-[600px] h-[400px] graphHolder", id:`graphHolder${x}`})
  //   );
  // }

  let graphScript = script();

  let forExport = div(
    {class: "flex flex-col gap-5"}
  )

  let getCase = async () => {
    loading.val = true;
    let firstRes = await fetch("/api/get-local-case", {
      method: "POST",
      headers:{
          "Content-Type": "application/json" 
      },
      body: JSON.stringify({
        tag: caseTag
      })
    }).catch((e)=>{
      console.log("FAILED");
      console.log(e);
    });

    let firstResObj = await firstRes.json().catch((e) => {
      console.log("FAILED ON JSON")
      console.log(e)
    });

    if ("failed" in firstResObj){
      console.log("FAILED")
      return;
    }

    clearInterval(progressCheckInterval);
    caseData.val = firstResObj;

    let numVisuals = 0;
    for (let i = 0; i < caseData.val["parts"].length; ++i){
      let part = caseData.val["parts"][i]
      van.add(
        forExport,
        t({class: "font-semibold text-2xl font-rope"}, "Question " + (i + 1)),
        t({class: "text-lg font-rope"}, part["question"].replace(/<VISUAL>[\s\S]*?<\/VISUAL>/g, ''))
      )

      if ("attachment" in part){
        let visual = part["attachment"];
        van.add(
          forExport,
          div({class: "w-[600px] h-[600px] graphHolder", id:`graphHolder${numVisuals}`}),
          script(
            visual.slice(visual.indexOf("<script>") + "<script>".length, visual.indexOf("</script>"))
          )
        )
        graphScript.innerHTML =
        numVisuals += 1;
      }
    }
    van.add(
      forExport,
      t({class: "font-semibold text-2xl font-rope"}, "Main Rubric"),
    )
    for (let key of Object.keys(caseData.val["rubric"])){
      van.add(
        forExport,
        t({class: "font-semibold text-xl font-rope"}, "Question " + key),
      )
      for (let obj of caseData.val["rubric"][key]){
        van.add(
          forExport,
          t({class: "text-md font-rope"}, obj["criterion"] + ": -" + obj["deduction"]),
        )
      }
    }

    van.add(
      forExport,
      t({class: "font-semibold text-2xl font-rope"}, "Strategy Guide"),
    )

    for (let key of Object.keys(caseData.val["strategy_rubric"])){
      van.add(
        forExport,
        t({class: "font-semibold text-xl font-rope"}, "Question " + key),
        t({class: "text-md font-rope"}, caseData.val["strategy_rubric"][key]),
      )
    }

    
    
    loading.val = false;
    caseReady.val = true;

  }

  getCase();

  let showingExport = van.state(false);

  return div(
    {class: "flex flex-col h-full justify-center p-5"},
    NavBar(),
    () => loading.val ? div(
        {class: "flex flex-col w-full h-full justify-center items-center gap-2"},
        div(
          {class: "flex flex-col items-center gap-7"},
          div({class: "loader-blob translate-y-[-10px]"}),
          h1(
            {class: "font-rope font-semibold text-4xl self-baseline"},
            loadingDescription.val
          ),
        ),
        div({class: "h-4"}),
        () => progressAmount.val != -100 ? t(
          {class: "font-rope font-semibold text-xl"},
          progressLabel.val
        ) : "",
        () => progressAmount.val != -100 ? 
        div({class: "w-[90%] sm:max-w-[500px] rounded-full h-2.5 bg-steel"},
          div({class: "bg-dark h-2.5 rounded-full", style: `width: ${progressAmount.val}%;`}),
        ) : ""
      ) :
    (showingExport.val ?
      div(
        {class: "w-full flex justify-center h-[80%]", id:"toPrint"},
        div(
          {class: "flex flex-col w-full max-w-[600px] gap-10"},
          h1(
            {class: "font-rope font-semibold text-4xl"},
            caseData.val["title"]
          ),
          forExport
        )
      )
    :

    (
      caseReady.val ? 
      div(
        {class: "flex flex-col w-full h-[80%] items-center gap-10"},
        h1(
          {class: "font-rope font-semibold text-4xl"},
          "Case Generated!"
        ),
        div(
          {class: "flex flex-col w-full sm:max-w-[600px] gap-5"},
          div(
            {class: "bg-white flex flex-col gap-5 border-4 border-steel p-2 rounded-lg text-left w-full relative"},
            t({class: "font-semibold text-2xl font-rope"}, caseData.val["title"]),
            t({class: "font-rope text-xl"}, "Number of Questions: " + caseData.val["parts"].length),
            t({class: "font-rope text-xl"}, "Focus: " + caseData.val["style"]),
            div(
              {class: "flex justify-end w-full"},
              div(
              {class: "flex w-full justify-end p-2 max-w-[300px] rounded-lg font-semibold font-rope text-sm h-[50px]"},
                button(
                  {class: "p-2 max-w-[300px] z-0 pl-4 pr-8 rounded-lg font-semibold font-rope text-sm text-gold bg-gold absolute"},
                  div(
                    {class: "flex gap-2 w-full"},
                    span(
                      {class: "material-symbols-outlined"},
                      "download"
                    ),
                    t("Export Case")
                  )
                ),
                button(
                  {class: "p-2 max-w-[300px] z-0 pl-4 pr-8 rounded-lg font-semibold font-rope text-sm text-white bg-dark hover:translate-y-[-5px] absolute", onclick: () => {
                    showingExport.val = true;
                    setTimeout(()=>{
                      print();
                    }, 100);
                  }},
                  div(
                    {class: "flex gap-2 w-full justify-center items-center"},
                    span(
                      {class: "material-symbols-outlined"},
                      "download"
                    ),
                    t("Export Case")
                  )
                ),
              )
            )
            // div(
            //   {class: "flex flex-row gap-2 absolute bottom-2 right-2 hover:cursor-pointer hover:underline hover:underline-offset-4 font-rope"},
            //   span(
            //     {class: "material-symbols-outlined"},
            //     "download"
            //   ),
            //   "Download Case PDF"
            // )
          ),
        ),
        PrimaryButton({
          onclick: () => {
            console.log("HAHA")
          },
          text: "Start Case"
        }),
        div({class: "h-5"}),
        SmallLink({
          href: "/",
          text: "Back to Cases"
        }),
        div({class: "h-5"}),
        graphScript
      )
      :
      div(
        {class: "flex flex-col w-full h-[80%] items-center gap-10"},
        h1(
          {class: "font-rope font-semibold text-4xl"},
          "Case Generator"
        ),
        div(
          {class: "flex flex-col w-full sm:max-w-[600px] gap-5"},
          div(
            {class: "bg-[#FCE19C] p-2 rounded-lg text-center w-full relative"},
            t("Generated cases can have mistakes and may be less realistic"),
            span(
              {class: "material-symbols-outlined text-dark absolute left-4"},
              "work_alert"
            )
          ),
          textInput
        ),
        
      )
    ))
  )
}

export default PDFViewerPage;