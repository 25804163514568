import van from "vanjs-core"

const {button, span, t} = van.tags

const difficulty_to_color = {
  "easy" : "text-asparagus",
  "medium" : "text-gold", 
  "hard" : "text-fire",
  "custom" : "text-thanos"
};

const CaseRow = ({status, title, difficulty, style, source, onClick}) => {
  return button(
    {
      class: "flex w-full flex-grow gap-5 bg-white hover:bg-steel border-steel border-2 p-2 rounded-lg",
      onclick: () => {onClick(title)}
    },
    span(
    {class: `material-symbols-outlined min-w-[50px] ${status.toLowerCase() == 'done' ? 'text-asparagus' : (status.toLowerCase() == 'attempted' ? 'text-gold' : '')}`},
    status.toLowerCase() == "done" ? "check_circle" : (status.toLowerCase() == 'attempted' ? 'clock_loader_20' : '')
    ),
    t({class: "font-rope text-base text-dark w-[248px] text-ellipsis text-left whitespace-nowrap overflow-hidden"}, title),
    t({class: `font-rope text-base text-left md:min-w-28 ${difficulty_to_color[difficulty.toLowerCase()]}`}, difficulty),
    t({class: "font-rope text-base text-left md:min-w-40"}, style),
    t({class: "font-rope text-base text-left md:min-w-40 text-ellipsis whitespace-nowrap overflow-hidden"}, source),
  )
}

export default CaseRow;