import van from "vanjs-core";

import { NavBar } from "./components/NavBar";
import SideStats from "./components/SideStats";
import { Picker } from "./components/Picker";
import CaseRow from "./components/CaseRow";

import Pagination from './components/Pagination';
import FilterChip from "./components/FilterChip";
import StartCaseModal from "./components/StartCaseModal";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app, globalUser } from "./globals";

const { div, t, button, span, input} = van.tags;

const dummy_data = [
  {status: "done", title: "Allison's Analysis", source: "Bane", style: "Interviewer Led", difficulty: "Hard"},
  {status: "", title: "Nathan's Pharma", source: "CCG", style: "Interviewee Led", difficulty: "Medium"},
  {status: "attempted", title: "Mathew's Forecasts", source: "Casenow", style: "Market Sizing", difficulty: "Easy"},
  {'status': 'attempted',
  'title': 'Fast Food Franchise Strategy',
  'style': 'Interviewee Led',
  'source': 'PwC',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Pharmaceutical R&D Optimization',
  'style': 'Interviewee Led',
  'source': 'KPMG',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewer Led',
  'source': 'Oliver Wyman',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Market Sizing',
  'source': 'PwC',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewee Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Energy Sector Transformation',
  'style': 'Market Sizing',
  'source': 'Oliver Wyman',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Consumer Electronics Market Entry',
  'style': 'Interviewee Led',
  'source': 'EY',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Luxury Brand Market Sizing',
  'style': 'Interviewee Led',
  'source': 'Accenture',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Market Sizing',
  'source': 'Roland Berger',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewee Led',
  'source': 'PwC',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Luxury Brand Market Sizing',
  'style': 'Interviewer Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'Roland Berger',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Luxury Brand Market Sizing',
  'style': 'Interviewer Led',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Market Sizing',
  'source': 'AT Kearney',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Non-Profit Impact Assessment',
  'style': 'Market Sizing',
  'source': 'McKinsey & Company',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Healthcare Market Entry',
  'style': 'Interviewee Led',
  'source': 'Roland Berger',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Global Expansion Feasibility',
  'style': 'Interviewer Led',
  'source': 'McKinsey & Company',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewee Led',
  'source': 'Deloitte',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewee Led',
  'source': 'Roland Berger',
  'difficulty': 'hard'},
 {'status': 'done',
  'title': 'Healthcare Market Entry',
  'style': 'Market Sizing',
  'source': 'LEK Consulting',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Healthcare Market Entry',
  'style': 'Interviewee Led',
  'source': 'Bain & Company',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Global Expansion Feasibility',
  'style': 'Interviewer Led',
  'source': 'McKinsey & Company',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Interviewer Led',
  'source': 'Roland Berger',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Interviewee Led',
  'source': 'Bain & Company',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Interviewee Led',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Energy Sector Transformation',
  'style': 'Interviewee Led',
  'source': 'McKinsey & Company',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Global Expansion Feasibility',
  'style': 'Market Sizing',
  'source': 'Accenture',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Consumer Electronics Market Entry',
  'style': 'Interviewer Led',
  'source': 'KPMG',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'McKinsey & Company',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Pharmaceutical R&D Optimization',
  'style': 'Interviewee Led',
  'source': 'KPMG',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Pharmaceutical R&D Optimization',
  'style': 'Interviewee Led',
  'source': 'EY',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Fast Food Franchise Strategy',
  'style': 'Market Sizing',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Interviewer Led',
  'source': 'LEK Consulting',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Retail Expansion Strategy',
  'style': 'Market Sizing',
  'source': 'Bain & Company',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Interviewee Led',
  'source': 'Oliver Wyman',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Fast Food Franchise Strategy',
  'style': 'Market Sizing',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'done',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Market Sizing',
  'source': 'LEK Consulting',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewee Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Energy Sector Transformation',
  'style': 'Market Sizing',
  'source': 'EY',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Market Sizing',
  'source': 'Deloitte',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'PwC',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewee Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewer Led',
  'source': 'Roland Berger',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'LEK Consulting',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Luxury Brand Market Sizing',
  'style': 'Interviewer Led',
  'source': 'EY',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Market Sizing',
  'source': 'McKinsey & Company',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Telecom Profitability Analysis',
  'style': 'Market Sizing',
  'source': 'Bain & Company',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Healthcare Market Entry',
  'style': 'Interviewer Led',
  'source': 'Oliver Wyman',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Interviewee Led',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Energy Sector Transformation',
  'style': 'Interviewee Led',
  'source': 'PwC',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewee Led',
  'source': 'AT Kearney',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Retail Expansion Strategy',
  'style': 'Interviewee Led',
  'source': 'McKinsey & Company',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Retail Expansion Strategy',
  'style': 'Interviewer Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'Roland Berger',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Consumer Electronics Market Entry',
  'style': 'Market Sizing',
  'source': 'AT Kearney',
  'difficulty': 'hard'},
 {'status': 'done',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewer Led',
  'source': 'Oliver Wyman',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Retail Expansion Strategy',
  'style': 'Interviewer Led',
  'source': 'PwC',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Interviewee Led',
  'source': 'Deloitte',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Market Sizing',
  'source': 'Bain & Company',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Energy Sector Transformation',
  'style': 'Interviewee Led',
  'source': 'Oliver Wyman',
  'difficulty': 'medium'},
 {'status': 'not started',
  'title': 'Pharmaceutical R&D Optimization',
  'style': 'Market Sizing',
  'source': 'KPMG',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewer Led',
  'source': 'McKinsey & Company',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Healthcare Market Entry',
  'style': 'Market Sizing',
  'source': 'PwC',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewee Led',
  'source': 'Bain & Company',
  'difficulty': 'hard'},
 {'status': 'done',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewee Led',
  'source': 'Deloitte',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Pharmaceutical R&D Optimization',
  'style': 'Interviewer Led',
  'source': 'Roland Berger',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Interviewee Led',
  'source': 'Accenture',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewee Led',
  'source': 'AT Kearney',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Consumer Electronics Market Entry',
  'style': 'Interviewer Led',
  'source': 'Oliver Wyman',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Retail Expansion Strategy',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Market Sizing',
  'source': 'Bain & Company',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Interviewee Led',
  'source': 'KPMG',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Interviewer Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'hard'},
 {'status': 'not started',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Interviewee Led',
  'source': 'LEK Consulting',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Public Transport Pricing Strategy',
  'style': 'Interviewer Led',
  'source': 'McKinsey & Company',
  'difficulty': 'hard'},
 {'status': 'attempted',
  'title': 'Healthcare Market Entry',
  'style': 'Interviewee Led',
  'source': 'Accenture',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Healthcare Market Entry',
  'style': 'Market Sizing',
  'source': 'LEK Consulting',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Interviewee Led',
  'source': 'Boston Consulting Group',
  'difficulty': 'medium'},
 {'status': 'attempted',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewee Led',
  'source': 'EY',
  'difficulty': 'hard'},
 {'status': 'done',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewer Led',
  'source': 'Accenture',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Fast Food Franchise Strategy',
  'style': 'Market Sizing',
  'source': 'PwC',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Sustainability Initiative Assessment',
  'style': 'Interviewee Led',
  'source': 'Roland Berger',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Healthcare Market Entry',
  'style': 'Interviewer Led',
  'source': 'Oliver Wyman',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Non-Profit Impact Assessment',
  'style': 'Interviewer Led',
  'source': 'McKinsey & Company',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Energy Sector Transformation',
  'style': 'Interviewer Led',
  'source': 'AT Kearney',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Interviewer Led',
  'source': 'Bain & Company',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'E-Commerce Revenue Growth',
  'style': 'Interviewer Led',
  'source': 'Accenture',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Cost Optimization for Manufacturing',
  'style': 'Market Sizing',
  'source': 'Boston Consulting Group',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Retail Expansion Strategy',
  'style': 'Market Sizing',
  'source': 'PwC',
  'difficulty': 'medium'},
 {'status': 'done',
  'title': 'Telecom Profitability Analysis',
  'style': 'Interviewer Led',
  'source': 'LEK Consulting',
  'difficulty': 'hard'},
 {'status': 'done',
  'title': 'Automotive Supply Chain Efficiency',
  'style': 'Interviewer Led',
  'source': 'KPMG',
  'difficulty': 'easy'},
 {'status': 'not started',
  'title': 'Consumer Electronics Market Entry',
  'style': 'Interviewer Led',
  'source': 'Roland Berger',
  'difficulty': 'easy'},
 {'status': 'attempted',
  'title': 'Non-Profit Impact Assessment',
  'style': 'Interviewee Led',
  'source': 'Deloitte',
  'difficulty': 'easy'},
 {'status': 'done',
  'title': 'Consumer Electronics Market Entry',
  'style': 'Interviewee Led',
  'source': 'Roland Berger',
  'difficulty': 'hard'}]

const statusOptions = ["Not Started", "Done"];
const difficultyOptions = ["Easy", "Medium", "Hard", "Custom"];
const styleOptions = ["Market Entry", "Declining Revenue", "Market Sizing", "M&A", "Strategy"];
const sourceOptions =  ["Columbia", "Duke", "CCG"];

const HomePage = () => {
  let caseRows = div(
    {class: "flex flex-col gap-1 overflow-x-auto"},
  );

  let pageRange = van.state([1, 10]);
  let pageMessage = van.state("");

  let filteredData = van.state([]);
  let initialData = van.state([]);

  let loading = van.state(true);
  let loadingPlaceholder = div(
    {class: "flex flex-col gap-2"}
  );
  for (let i = 0; i < 10; ++i){
    van.add(
      loadingPlaceholder, 
      div(
        {class: "flex h-10 w-full gap-5 bg-steel border-steel border-2 p-2 rounded-lg animate-pulse",}
      )
    )
  }

  let activeFilters = van.state([]);

  let searchFocus = van.state(false);

  let showCaseModal = van.state(false);
  let caseModalTitle = van.state("");
  let selectedCaseId = van.state("");

  const initState = async () => {
    // await new Promise(r => setTimeout(r, 2000));
    const db = getFirestore(app);
    const collectionRef = collection(db, "case-info")
    // Fetch all documents
    const querySnapshot = await getDocs(collectionRef);
    console.log(querySnapshot.length)

    // Process each document
    let starting_data = []
    querySnapshot.forEach((doc) => {
      let d = doc.data()
      d["status"] = "not started";
      console.log(d)
      starting_data.push(d);
    });

    const userCasesRef = collection(db, "user-cases")
    const custom_q = query(userCasesRef, where("owner", "==", globalUser.val?.uid ?? ""));
    const querySnapshot2 = await getDocs(custom_q);
    querySnapshot2.forEach((doc) => {
      let d = doc.data()
      console.log(d["owner"])
      console.log(globalUser.val?.uid)
      d["status"] = "not started";
      d["caseId"] = "<CUSTOM>" + doc.id
      console.log(d)
      starting_data.push(d);
    });


    starting_data.sort((a, b) => {
      if (a.case_number == ""){
        a.case_number = 10000;
      }
      if (b.case_number == ""){
        b.case_number = 10000;
      }
      return a.case_number - b.case_number;
    });

    let ref = await doc(db, "users", globalUser.val?.uid ?? "");
    let snap = await getDoc(ref);
    let proceed = snap.exists();
    let data = snap.exists() ? snap.data() : {};
    if (!snap.exists()) {
      const q = query(
        collection(db, "users"),
        where("uid", "==", globalUser.val?.uid),
      );
      let snapList = await getDocs(q);
      if (snapList.size > 0) {
        proceed = true;
        data = snapList.docs.at(0).data();
      }
    }

    if (proceed){
      let caseIdsCompleted = new Set()
      for (let caseObj of data["problemsCompleted"]){
        console.log("Completed ", caseObj["problemId"])
        caseIdsCompleted.add(caseObj["problemId"])
      }
      for (let i = 0; i < starting_data.length; ++i){  
        let caseRowObj = starting_data[i]
        console.log("Checking ", caseRowObj)
        if (caseIdsCompleted.has(caseRowObj["caseId"])){
          console.log("Marking done")
          starting_data[i].status = "done"
        }
      }
    }

    filteredData.val = starting_data;
    initialData.val = starting_data;
    loading.val = false;
  }

  const trigger = van.state(true);
  van.derive(() => trigger.val && initState());
  trigger.val = false;

  van.derive(() => {
    caseRows.innerHTML = "";
    for (let i = pageRange.val[0] - 1; i < pageRange.val[1]; ++i){
      if (i >= filteredData.val.length){
        break;
      }
      van.add(
        caseRows,
        div(
          {class: "w-full flex"},
          CaseRow({status: filteredData.val[i].status, title: (filteredData.val[i].case_number > 1000 ? "" : filteredData.val[i].case_number + ". ") + filteredData.val[i].title.replace(/\"/g, ""), source: filteredData.val[i].source, style: filteredData.val[i].style, difficulty: filteredData.val[i].difficulty, onClick: (title) => {
            caseModalTitle.val = title;
            window.scrollTo({
              top: 0,
              behavior: 'auto' 
            });
            showCaseModal.val = true;
            selectedCaseId.val = filteredData.val[i].caseId;
            console.log("SELECTED ", selectedCaseId.val)
          }}),
        )
      )
    }
    console.log("Acting here...");
    pageMessage.val = `${pageRange.val[0]}-${pageRange.val[1]}`;
  })

  let filters = 
  div({class: "w-full flex gap-1"}, 
    
  );

  let searchInput = input(
    {
      class: "min-w-[200px] font-rope text-sm border-none outline-none",
      onfocus: ()=>{searchFocus.val = true;},
      onblur: ()=>{searchFocus.val = false;},
      oninput: ()=>{
        updateFilteredData();        
      },
      placeholder: "Search"
    }
  )

  function updateFilteredData(){
    let v = searchInput.value;
    let updated = initialData.val.filter((x) => x.title.trim().toLowerCase().indexOf(v.trim().toLowerCase()) >= 0);
    for (let filter of activeFilters.val){
      console.log("Filtering", Object.keys(filter)[0], Object.values(filter)[0]);
      console.log(updated[0]);
      updated = updated.filter(x => x[Object.keys(filter)[0]].toLowerCase().trim() == Object.values(filter)[0].trim().toLowerCase());
    }
    filteredData.val = updated;
    pageRange.val = [1, 10];
  }


  return div(
    { class: "flex flex-col h-full p-5" },
    () => showCaseModal.val ? StartCaseModal({onCancel: () => {showCaseModal.val = false;}, 
    onConfirm: ()=> {}, label: caseModalTitle.val, caseId: selectedCaseId.val}) : "",
    NavBar(),
    div({class: "min-h-20"}),
    div(
      {class: "flex flex-col flex-grow h-[80%]"},
      div(
        {class: "flex w-full gap-5 flex-wrap"},
        div(
          {class: "min-w-1"}
        ),
        div(
          {class: "flex flex-col gap-1 flex-grow w-full lg:w-0"},
          div(
            {class: "flex gap-2 w-full flex-wrap"},
            Picker({label: "Status", options: statusOptions, onChosen: (x) => {
              for (let filter of activeFilters.val){
                if (filter?.status == statusOptions[x]){
                  return;
                }
              }
              let f = FilterChip({
                message: statusOptions[x],
                onRemove: () => {
                  activeFilters.val = activeFilters.val.filter(item => item?.status !== statusOptions[x])
                  updateFilteredData();
                  f.remove();
                }
              })
              van.add(
                filters,
                f
              )
              activeFilters.val = [...activeFilters.val, {status: statusOptions[x]}];
              updateFilteredData();
            }}),
            Picker({label: "Difficulty", options: difficultyOptions, onChosen: (x) => {
              for (let filter of activeFilters.val){
                if (filter?.difficulty?.trim()?.toLowerCase() == difficultyOptions[x].trim().toLowerCase()){
                  return;
                }
              }
              let f =  FilterChip({
                message: difficultyOptions[x],
                onRemove: () => {
                  activeFilters.val = activeFilters.val.filter(item => item?.difficulty?.trim()?.toLowerCase() !== difficultyOptions[x].trim().toLowerCase())
                  updateFilteredData();
                  f.remove();
                }
              })
              van.add(
                filters,
                f
              )
              activeFilters.val = [...activeFilters.val, {difficulty: difficultyOptions[x]}];
              updateFilteredData();
            }}),
            Picker({label: "Focus", options: styleOptions, onChosen: (x) => {
              for (let filter of activeFilters.val){
                console.log("Comparing ", filter?.style?.trim()?.toLowerCase(), styleOptions[x]?.trim()?.toLowerCase());
                if (filter?.style?.trim()?.toLowerCase() == styleOptions[x]?.trim()?.toLowerCase()){
                  console.log("Quitting");
                  return;
                }
              }
              let f = FilterChip({
                message: styleOptions[x],
                onRemove: () => {
                  activeFilters.val = activeFilters.val.filter(item => item?.style?.trim()?.toLowerCase() !== styleOptions[x].trim().toLowerCase());
                  updateFilteredData();
                  f.remove();
                }
              })
              van.add(
                filters,
                f
              )
              activeFilters.val = [...activeFilters.val, {style: styleOptions[x]}];
              updateFilteredData();
            }}),
            Picker({label: "Source", options: sourceOptions, onChosen: (x) => {
              for (let filter of activeFilters.val){
                console.log(filter)
                if (filter?.source?.trim()?.toLowerCase() == sourceOptions[x].trim().toLowerCase()){
                  return;
                }
              }
              let f = FilterChip({
                message: sourceOptions[x],
                onRemove: () => {
                  activeFilters.val = activeFilters.val.filter(item => item?.source?.trim()?.toLowerCase() !== sourceOptions[x].trim().toLowerCase());
                  updateFilteredData();
                  f.remove();
                }
              })
              van.add(
                filters,
                f
              )
              activeFilters.val = [...(activeFilters.val), {source: sourceOptions[x]}];
              updateFilteredData();
              // console.log("Updated:");
              // console.log(activeFilters.val);
            }}),
            div(
              {class: `flex flex-grow gap-2 p-2 border-steel border-2 bg-white rounded-lg mb-6`},
              span(
                {class: "material-symbols-outlined !text-md"},
                "search"
              ),
              searchInput
            )
          ),
          filters,
          div(
            {class: "w-full flex"},
            div(
              {class: "flex w-full flex-grow gap-5"},
              t({class: "font-rope text-sm text-left text-dark min-w-[50px] whitespace-nowrap overflow-hidden"}, "Status"),
              t({class: "font-rope text-sm text-left text-dark w-[248px]"}, "Title"),
              t({class: `font-rope text-sm text-left text-dark md:min-w-28`}, "Difficulty"),
              t({class: "font-rope text-sm text-left text-dark md:min-w-40"}, "Focus"),
              t({class: "font-rope text-sm text-left text-dark md:min-w-40 whitespace-nowrap overflow-hidden"}, "Source"),
            )
          ),
          () => loading.val ? loadingPlaceholder : "",
          caseRows,
          div({class: "min-h-2"}),
          () => Pagination({max: filteredData.val.length, onBack: () => {
              if (pageRange.val[0] == 1){
                return;
              }
              pageRange.val = [pageRange.val[0] - 10, pageRange.val[1] - 10];
            }, onForward: () => {
              if (pageRange.val[1] >= filteredData.val.length){
                return;
              }
              pageRange.val = [pageRange.val[0] + 10, pageRange.val[1] + 10];
            }
          })
        ),
        () => SideStats({data: initialData.val, styles: styleOptions}),
        div(
          {class: "min-w-2"}
        ),
      )
    )
  );
};

export default HomePage;
