import van from "vanjs-core"
import {route} from "./components/Router";
import PrimaryButton from "./components/PrimaryButton";
import { NavBar } from "./components/NavBar";
import { demoCaseNumber, getDemoCaseData, globalInterviewer, globalAudioStream, globalTimerEnabled, globalUser, specifiedCaseId, specifiedCaseName, specifiedCaseNumber, speechRecognition, globalVideoStream, globalPauseTime } from "./globals";
import { getIdToken } from "firebase/auth";
import { formatElapsedTimeForCasing, formatMessageForChat } from "./helpers/formatting";
import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";
import { FeedbackDisplay } from "./components/FeedbackDisplay";


const {div, span, t, video, textarea, button, audio, img, h1, script} = van.tags;

const interviewerToIcon = {
  "Joe" : "face_6",
  "Chloe" : "face_2",
  "Karen" : "face_3",
  "Goggins" : "face_5",
  "Random" : "psychology_alt"
}

const EYE_CHECK_FRAME_INTERVAL = 30;
const EYE_CONTACT_THRESHOLD = 0.75;
const SMILE_THRESHOLD = 0.5;


let globalInterval;

const VideoCasePage = () => {
  let videoOn = van.state(false);
  let videoEl = video({class: "border-4 border-steel flex-grow rounded-lg h-full"}, t());

  let showWorkInput = textarea(
              {class: "w-full p-2 focus:outline-none resize-none flex-grow text-sm font-rope", placeholder: "Show Work As Needed Here"},
            );

  let emptyWorkspace = van.state(true);
  let workspace = div(
    {class: "flex flex-col w-full flex-grow-0 p-2 overflow-y-auto"}
  )

  let speechText = van.state("");
  let prevSpeechText = van.state("");
  let spoken = van.state(false);
  let inputDisabled = van.state(true);

  let lastSpeechTime = van.state("");

  let shownWork = van.state("");

  let showInterviewerMuted = van.state(true);

  let timerTime = van.state(0);
  let timerText = van.state("");
  let timerShow = van.state(false);

  let totalFrames = van.state(0);
  let badEyeFrames = van.state(0);
  let smileFrames = van.state(0);

  let feedbackShow = van.state(false);
  const loading = van.state(false);
  const progressLabel = van.state("");
  const progressAmount = van.state(0);
  const webcamUp = van.state(false);

  const feedbackData = van.state({});

  let activeFrameId = van.state(0);

  let ending = van.state(false);

  let imgCount = van.state(0);

  let openWebcamCallback = () => {
    console.log("Pressed!!");
    navigator.mediaDevices
    .getUserMedia({
        video: true,
    })
    .then((stream) => {
      webcamUp.val = true;
      globalVideoStream.val = stream;
      console.log("We're in")
        // Changing the source of video to current stream.
        videoEl.srcObject = stream;
        videoEl.addEventListener("loadeddata", async () => {
            videoEl.play();

            const filesetResolver = await FilesetResolver.forVisionTasks(
              "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
            );
            let faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
              baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
                delegate: "GPU"
              },
              outputFaceBlendshapes: true,
              runningMode: "VIDEO",
              numFaces: 1
            });

            let counter = -1;
            let prevData = {}

            async function detect() {
              counter += 1;
              const predictions = faceLandmarker.detectForVideo(videoEl, Date.now());
              // const predictions = faceLandmarker.detectForVideo(videoEl, Date.now());
              if (predictions.faceBlendshapes) {
                let blendShape = predictions.faceBlendshapes[0];
                if (blendShape){
                  let eyeCategories = ["eyeLookOutRight", "eyeLookOutLeft", "eyeLookOutUp", "eyeLookInRight", "eyeLookInLeft", "eyeLookUpLeft", "eyeLookUpRight", "eyeLookDownLeft", "eyeLookDownRight"];
                  let diff = 0;
                  let leftSmileScore = 0;
                  let rightSmileScore = 0;
                  for (let shape of blendShape.categories){
                    // if (counter % EYE_CHECK_FRAME_INTERVAL == 0){
                    //   console.log(shape.categoryName);
                    // }
                    if (counter % EYE_CHECK_FRAME_INTERVAL == 0 && eyeCategories.indexOf(shape.categoryName) >= 0){
                      if (counter != 0){
                        diff += Math.abs(prevData[shape.categoryName] - shape.score)
                      }
                      prevData[shape.categoryName] = shape.score
                    }
                    if (counter % EYE_CHECK_FRAME_INTERVAL == 0 && shape.categoryName == "mouthSmileLeft"){
                      if (counter != 0){
                        leftSmileScore = shape.score
                      }
                    }
                    if (counter % EYE_CHECK_FRAME_INTERVAL == 0 && shape.categoryName == "mouthSmileRight"){
                      if (counter != 0){
                        rightSmileScore = shape.score
                      }
                    }
                  } 
                  if (diff > EYE_CONTACT_THRESHOLD){
                    console.log("Not maintaining eye contact")
                    badEyeFrames.val += 1;
                  }   
                  if ((leftSmileScore + rightSmileScore) / 2 > SMILE_THRESHOLD){
                    console.log("Smiling!")
                    smileFrames.val = smileFrames.val + 1;
                  }                      
                }
                if (counter % EYE_CHECK_FRAME_INTERVAL == 0){
                  counter = 0;
                  totalFrames.val = totalFrames.val + 1;
                }
                // const leftEye = [landmarks[33], landmarks[133]]; // Example landmarks for left eye
                // const nose = landmarks[1]; // Nose bridge
                
                // // Calculate Eye Gaze (e.g., by comparing iris position with eye bounds)
                // const iris = landmarks[468]; // Approximate iris position
                // console.log(iris);
                
                // const isLookingStraight = (iris.x > leftEye[0].x && iris.x < leftEye[1].x);
                
                // if (!isLookingStraight) {
                //   console.log("Not making eye contact");
                // }
              }
              else{
                await new Promise(resolve => setTimeout(resolve, 1000)); // Sleeps for 1 second
              }
              
              activeFrameId.val = requestAnimationFrame(detect);
            }
            console.log("About to run");
            detect();
        });
        videoOn.val = true;

        // const filesetResolver = await FilesetResolver.forVisionTasks(
        //    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
        // );
        // const faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
        //   baseOptions: {
        //     modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
        //   },
        //   runningMode: "VIDEO",
        //   numFaces: 1,
        // });

        
    })
    .catch((e)=>{
      console.log(e);
    });
  }

  const handleInterviewerMsg = async (res) => {
    inputDisabled.val = false;
    console.log("HERE and changed");
    let f = [...res.entries()];
    let textData = await f[0][1].text();
    if (textData == "FORBIDDEN"){
      route("/nomembership");
      return;
    }
    if (textData == "NOACCESS"){
      route("/noaccess");
      return;
    }
    if (textData == "EXPIRED"){
      route("/expired");
      return;
    }
    // addMessageToChat(
    //   "Interviewer", 
    //   formatMessageForChat(textData)
    // )

    if (ending.val){
      return;
    }

    if (textData.indexOf("<END>") >= 0){
      console.log("Should end!");
      ending.val = true;
    }

    window.speechSynthesis.cancel();
    var msg = new SpeechSynthesisUtterance();

    speechRecognition.stop()

    console.log(textData);

    if (textData.indexOf("<IMG>") >= 0){
      let url = textData.substring(textData.indexOf("<IMG>") + 5, textData.indexOf("</IMG>"))

      console.log(url);

      if (url.indexOf("<script>") >= 0 && url.indexOf("</script>") >= 0){
        textData = textData.replace(/<VISUAL>[\s\S]*?<\/VISUAL>/g, '');
        let scriptContents = url.slice(url.indexOf("<script>") + "<script>".length, url.indexOf("</script>") )
        console.log("YIPPEE.")
        console.log(scriptContents)
        van.add(
          workspace,
          div(
            {class: "space-y-2 border-l-dark border-l-2 p-2"},
            t(
              {class: "w-full pb-0 font-rope text-sm font-semibold flex-grow-0"},
              "Interviewer"
            ),
            div({class: "w-[500px] h-[300px]", id: `graphHolder${imgCount.val}`}),
            script(
              scriptContents
            )
          )
        )
        imgCount.val = imgCount.val + 1;
      }
      else{
        console.log("IMG URL ", url)
      emptyWorkspace.val = false;
      van.add(
        workspace,
        div(
          {class: "space-y-2 border-l-dark border-l-2 p-2"},
          t(
            {class: "w-full pb-0 font-rope text-sm font-semibold flex-grow-0"},
            "Interviewer"
          ),
          img(
            {class: "w-full h-auto", src: url}
          )
        )
      )
      }
    }


    msg.text = textData; 


    if (f.length > 1){
      let audioData = f[1][1]
      if (audioData) {
        const audioBlob = audioData;
        let reader = new FileReader();
        reader.onload = () => {
            document.getElementById("theAudio").src = reader.result.toString();
            document.getElementById("theAudio").play();
        };
        reader.readAsDataURL(audioBlob);
      }
      else{
        window.speechSynthesis.speak(msg.replace(/<END>/g, ""));
      }
    }
    else{
      window.speechSynthesis.speak(msg.replace(/<END>/g, ""));
    }


  }

  const submitResponse = async (savedInput) => {
    if (speechText.val.trim() == ""){
      // console.log("Prevented blanked")
      return;
    }
    if (!showInterviewerMuted.val){
      console.log("Cannot talk while interviewer talking")
      return;
    }
    console.log("Here!");
    inputDisabled.val = true;
    // inputPlaceholder.val = "Waiting for interviewer...";

    // van.add(
    //   workspace,
    //   t(
    //     {class: "w-full p-2 pb-0 border-l-dark border-l-2 font-rope text-sm font-semibold flex-grow-0"},
    //     "You Said"
    //   ),
    //   t(
    //     {class: "w-full p-2 border-l-dark border-l-2 font-rope flex-grow-0"},
    //     savedInput
    //   )
    // );
    showWorkInput.value = "";
    emptyWorkspace.val = false;


    timerShow.val = false;
    clearInterval(globalInterval);
    timerTime.val = 0;

    let to_send = savedInput;
    if (shownWork.val != ""){
      to_send = "Candidate Work:\n" + shownWork.val + "\nEnd of Candidate Work\n" + savedInput;
    }

    shownWork.val = "";

    let rawRes = await fetch("/api/complete", {
      method: "POST",
      headers:{
          "Content-Type": "application/json" 
      },
      body: JSON.stringify({
        message: to_send,
        highAudioQuality: true
      })
    });
    let parsedRes = await rawRes.formData();
    handleInterviewerMsg(parsedRes);

  }

  async function setupPulseEffect() {
    const audio = document.getElementById('theAudio');

    // Set up audio context and analyser
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Smaller size for responsiveness
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    // Connect the audio element to the analyser
    const source = audioContext.createMediaElementSource(audio);
    source.connect(analyser);
    analyser.connect(audioContext.destination); // Connect to output

    function animatePulse() {
      // Get audio data
      analyser.getByteFrequencyData(dataArray);

      // Calculate average volume
      const volume = dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length;

      // Map volume to scale and opacity
      const scale = 1 + Math.min(volume / 100, 1); // Max scale of 2
      const opacity = Math.min(volume / 200, 1);  // Max opacity of 1

      // Apply styles
      interviewerIconEffect.style.transform = `translate(-50%, -50%) scale(${scale})`;
      interviewerIconEffect.style.opacity = opacity;

      requestAnimationFrame(animatePulse);
    }

    // Start animation when the audio plays
    audio.addEventListener('play', () => {
      audioContext.resume(); // Ensure context is running
      animatePulse();
      showInterviewerMuted.val = false;
    });

    audio.addEventListener('ended', () => {
      speechText.val = "";
      spoken.val = false;
      if (ending.val){
        setTimeout(() => {
          console.log("In the end")
          loading.val = true;
          let progressCheckInterval = setInterval(async ()=>{
            console.log("Polling!");
            let res = await fetch("/api/scoreprogress");
            let data = await res.json();
            progressLabel.val = data["progress"][0].toString() + " / " + data["progress"][1].toString();
            progressAmount.val =  (data["progress"][0] / data["progress"][1]) * 100
          }, 2000);
          fetch("/api/finalscore").then( async (res)=>{
            let data = await res.json();
            feedbackData.val = data;
            feedbackShow.val = true;
            loading.val = false;
            clearInterval(progressCheckInterval);
          });       
        }, 500);
        return;
      }
      audioContext.resume(); // Ensure context is running
      setTimeout(() => {
        showInterviewerMuted.val = true;
        speechRecognition.start();
      }, 500)
      setTimeout(() => {
        timerTime.val = 0;
        timerShow.val = true;
        clearInterval(globalInterval);
        globalInterval = setInterval(()=>{
            timerTime.val += 0.1;
            timerText.val = formatElapsedTimeForCasing(timerTime.val);
        }, 100);
      }, 0) //calculateSpeechDuration(textData))
    });
  }

  const initState = async () => {
    if (specifiedCaseNumber.val === -1){
      route('/');
      return;
    }
    window.onbeforeunload = (event) => {
      event.preventDefault();
      return "Data will be lost if you leave the page, are you sure?";
    };
    await getDemoCaseData();
    // console.log("GLOBAL USER: " + globalUser?.val?.uid)
    let token = await getIdToken(globalUser.val);

    setupPulseEffect();

    const constraints = {
        audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
        }
    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {

      let hasSpoken = false;
      globalAudioStream.val = stream;

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);

      speechRecognition.onresult = (event) => {
        // Loop through the results
        // for (let i = event.resultIndex; i < event.results.length; i++) {
        //   const transcript = event.results[i][0].transcript;

        //   if (event.results[i].isFinal) {
        //     // Add final transcriptions to speechText
        //     speechText.val = speechText.val + transcript + " ";
        //   }
        // }
        let newOne = "";
        for (const res of event.results) {
          newOne += res[0].transcript;
        }
        if (newOne.trim() == "" || !showInterviewerMuted.val){
          return;
        }
        if (timerTime.val - lastSpeechTime.val > 0.9){

        }
        lastSpeechTime.val = timerTime.val;
        console.log("X: ", newOne);
        speechText.val = newOne;
        spoken.val = true;
      }

      source.connect(analyser);

      analyser.fftSize = 256;
      const dataArray = new Uint8Array(analyser.frequencyBinCount);

      let silenceStart = null;
      const silenceThreshold = -40; // Adjust dB level
      const silenceDuration = 1500; // 1.5 seconds

      function detectSilence() {
        analyser.getByteTimeDomainData(dataArray);

        const rms = Math.sqrt(dataArray.reduce((sum, value) => {
          const normalized = (value / 128.0) - 1.0; // Normalize to [-1, 1]
          return sum + normalized * normalized;
        }, 0) / dataArray.length);

        const dB = 20 * Math.log10(rms);

        // if (dB > silenceThreshold) {
        //   // User has started speaking
        //   hasSpoken = true;
        //   silenceStart = null;
        // } else if (hasSpoken) {
        //   if (!silenceStart) silenceStart = Date.now();
        //   if (Date.now() - silenceStart > silenceDuration) {
        //     console.log("Detected: ", speechText.val);
        //     submitResponse(speechText.val);
        //     speechText.val = "";
        //     hasSpoken = false; // Reset for the next input
        //     speechRecognition.stop()
        //     setTimeout(()=>speechRecognition.start(), 200)
        //     // speechRecognition.abort()
        //     // speechRecognition.start()
        //     // if (!inputDisabled.val){
              
        //     // }
        //     // else{
        //     //   console.log("Prevented.");
        //     // }
            
        //   }
        // }

        // console.log("Detected: ", speechText.val);
        // // submitResponse(speechText.val);
        // speechText.val = "";
        // hasSpoken = false; // Reset for the next input
        // speechRecognition.stop()
        // setTimeout(()=>speechRecognition.start(), 200)

        setTimeout(()=>{

        }, 500)

        requestAnimationFrame(detectSilence);
      }
      detectSilence();

      // console.log("Detected: ", speechText.val);
      // // submitResponse(speechText.val);
      // speechText.val = "";
      // hasSpoken = false; // Reset for the next input
      // speechRecognition.stop()
      setInterval(()=>{
        // console.log("S ", speechText.val)
        // console.log("P ", prevSpeechText.val)
        if (prevSpeechText.val == speechText.val && spoken.val){
          console.log("Done!!");
          submitResponse(speechText.val);
          speechText.val = "";
          spoken.val = false;
          // speechRecognition.stop()
          // setTimeout(()=>speechRecognition.start(), 500)
        }
        prevSpeechText.val = speechText.val
      }, globalPauseTime.val * 1000);

      speechRecognition.start();
    }).catch(console.error);

    let firstRes = await fetch("/api/first-complete", {
      method: "POST",
      headers:{
          "Content-Type": "application/json" 
      },
      body: JSON.stringify({
        problemNumber: specifiedCaseNumber.val === -1 ? demoCaseNumber.val : specifiedCaseNumber.val,
        caseId: specifiedCaseNumber.val === -1 ? "NONE" : specifiedCaseId.val,
        jwt: token,
        highAudioQuality: true
      })
    }).catch((e)=>{
      console.log("FAILED");
      console.log(e);
    });
    let parsed = await firstRes.formData();
    handleInterviewerMsg(parsed);

  }

  const trigger = van.state(false);
  van.derive(() => trigger.val && initState())
  trigger.val = !trigger.val;

  let interviewerIconEffect = div(
    {class: `w-16 h-16 rounded-full bg-blue-500 top-0 left-0 absolute translate-x-[-50%] translate-y-[-50%]`},
  )

  return div(
    {class: "flex flex-col h-full p-5 justify-center"},
    NavBar(true),
    audio({class: "hidden", id: "theAudio"}),//theAudio
    () => {
      if (feedbackShow.val){
        return FeedbackDisplay(feedbackData.val, {
          cameraOn: webcamUp.val,
          smilePercent: Math.floor(100 * (smileFrames.val / totalFrames.val)),
          eyeContactPercent: Math.floor(100 * (totalFrames.val - badEyeFrames.val) / totalFrames.val)
        });
      }
      return loading.val ? div(
        {class: "flex flex-col w-full h-full justify-center items-center gap-2"},
        div(
          {class: "flex gap-7"},
          div({class: "loader translate-y-[-10px]"}),
          h1(
            {class: "font-rope font-semibold text-4xl self-baseline"},
            "Scoring..."
          ),
        ),
        div({class: "h-4"}),
        () => t(
          {class: "font-rope font-semibold text-xl"},
          progressLabel.val
        ),
        () => div({class: "w-[90%] sm:max-w-[500px] rounded-full h-2.5 bg-steel"},
          div({class: "bg-dark h-2.5 rounded-full", style: `width: ${progressAmount.val}%;`}),
        )
      ) :
      div(
        {class: "flex flex-col w-full h-[90%] justify-center items-center gap-4"},
        div(
          {class: "w-full flex sm:max-w-[1000px] max-w-[800px]"},
          t({class: "text-sm font-rope flex-grow text-dark text-left"}, specifiedCaseName.val),
          () => timerShow.val && globalTimerEnabled.val ? t(
                  {class: "font-rope font-semibold"},
                  ()=>timerText.val
                ) : ""
        ),
        div(
          {class: "flex w-full h-full gap-2 sm:flex-wrap flex-nowrap justify-center flex-col sm:flex-row"},
          div(
            {class: "flex flex-col gap-2 flex-grow max-w-[500px] sm:min-h-[500px] min-h-[200px]"},
            div(
              {class: "relative flex-grow h-full w-full  border-steel border-2 rounded-lg bg-white flex justify-center items-center"},
              div(
                {class: "relative"},
                interviewerIconEffect,
                div(
                  {class: `absolute top-0 left-0 translate-x-[-50%] translate-y-[-50%] w-16 h-16 rounded-full bg-steel !text-4xl flex justify-center items-center text-dark`},
                  span(
                    {class: "material-symbols-outlined !text-4xl"},
                    `${interviewerToIcon[globalInterviewer.val]}`
                  )
                )
              ),            
              t(
                {class: "absolute bottom-2 left-2 font-rope font-semibold"},
                // `Interviewer: ${globalInterviewer.val}`
                "Interviewer"
              ),
              () => showInterviewerMuted.val ? div(
                {class: "bg-steel rounded-full w-8 h-8 flex justify-center items-center absolute bottom-2 right-2"},
                span(
                    {class: "font-rope !font-semibold material-symbols-outlined"},
                    "mic_off"
                  ),
              ) : "",
            ),
            () => !videoOn.val ? div(
              {class: "flex-grow h-[400px] w-full sm:h-full  border-steel border-2 rounded-lg bg-white flex justify-center items-center"},
              PrimaryButton({
                text: "Open Webcam",
                onclick: openWebcamCallback,
              }),
            ) : "",
            () => videoOn.val ? videoEl : ""
          ),
          div(
            {class: "flex flex-col sm:h-full gap-2 flex-grow max-w-[500px]"},
            () => emptyWorkspace.val ? 
              div(
              {class: "sm:flex-grow h-[100px] sm:h-full w-full border-steel border-2 rounded-lg bg-white flex justify-center items-center"},
              t({class: "font-semibold text-gray-400 font-rope"}, "Work Shared With Interviewer")
              )
            :
              div(
                {class: "flex-grow h-full w-full border-steel border-2 rounded-lg bg-white flex"},
                workspace,
                div({class: "flex-grow"})
              )
            ,
            div(
              {class: "sm:flex-grow flex-grow-0 w-full border-steel min-h-[75px] sm:min-h-[200px] border-2 rounded-lg bg-white flex flex-col"},
              showWorkInput,
              div(
                {class: "flex w-full justify-end"},
                  // div(
                  //   {class: "flex p-2 max-w-[200px] pl-2 pr-4 rounded-lg font-semibold font-rope text-base h-[40px]"},
                  //   button(
                  //     {class: "p-2 flex items-center gap-1 max-w-[200px]z-0 pl-1 pr-4 rounded-lg font-semibold font-rope text-base text-gold bg-gold absolute"},
                  //     span(
                  //       {class: "material-symbols-outlined"},
                  //       "add"
                  //     ),
                  //     "Framework",
                  //   ),
                  //   button(
                  //     {class: "p-2 max-w-[200px] flex items-center gap-1 z-0 pl-1 pr-4 rounded-lg font-semibold font-rope text-base text-white bg-dark hover:translate-y-[-5px] absolute", onclick:() => {}},
                  //     span(
                  //       {class: "material-symbols-outlined"},
                  //       "add"
                  //     ),
                  //     "Framework",
                  //   ),
                  // ),
                
                div(
                  {class: "flex justify-center p-2 pr-4 mb-1 rounded-lg font-semibold font-rope text-base h-[50px] w-[50px] self-end"},
                  button(
                    {class: "w-[40px] h-[40px] flex justify-center items-center z-0 rounded-lg font-semibold font-rope text-base text-gold bg-gold absolute"},
                    span(
                      {class: "material-symbols-outlined"},
                      "publish"
                    ),
                  ),
                  button(
                    {
                      class: "w-[40px] h-[40px] z-0 rounded-lg font-semibold font-rope text-base text-white bg-dark hover:translate-y-[-5px] absolute", 
                      onclick: () => {
                        let v = showWorkInput.value;
                        shownWork.val = shownWork.val == "" ? v : shownWork.val + "\n" + v
                        van.add(
                          workspace,
                          t(
                            {class: "w-full p-2 pb-0 border-l-dark border-l-2 font-rope text-sm font-semibold flex-grow-0"},
                            "You Shared"
                          ),
                          t(
                            {class: "w-full p-2 border-l-dark border-l-2 font-rope flex-grow-0"},
                            v.trim()
                          )
                        );
                        showWorkInput.value = "";
                        emptyWorkspace.val = false;
                      }
                    },
                    span(
                      {class: "material-symbols-outlined"},
                      "publish"
                    ),              
                  ),
                ),

              )
              
            ),
            //TEMP
            // button(
            //   {
            //     class: "p-2 max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope  text-white bg-dark hover:bg-fire hover:text-light", 
            //     onclick: () => {
            //       loading.val = true;
            //       let progressCheckInterval = 0;
            //       fetch("/api/finalscore").then( async (res)=>{
            //         let data = await res.json();
            //         feedbackData.val = data;
            //         feedbackShow.val = true;
            //         cancelAnimationFrame(activeFrameId.val);
            //         if (globalAudioStream.val){
            //           globalAudioStream.val.getAudioTracks().forEach(track => track.stop());
            //         }
            //         if (globalVideoStream.val){
            //           globalVideoStream.val.getVideoTracks().forEach(track => track.stop());
            //         }
            //         loading.val = false;
            //         clearInterval(progressCheckInterval);
            //       });       
            //     }
            //   },
            //   "End Case" 
            // )
          )
        ),
      );
    }
  )
}

export default VideoCasePage;