import van from "vanjs-core"

const {div, button, t, span} = van.tags;

const Picker = ({label, options, onChosen}) => {
  
  const questionsDropdownVisible = van.state(false);

  return div(
    {class: "flex flex-col min-w-[125px]"},
    button(
      {
        class: "font-rope bg-white text-sm sm:text-md font-semibold hover:bg-steel p-2 rounded-lg  border-2 border-steel",
        onclick: ()=>{
          questionsDropdownVisible.val = !questionsDropdownVisible.val;
        }
      },
      div(
        {class: "flex flex-row gap-1 justify-center"},
        t(
          {class: "pl-2"},
          `${label}`
        ),
        div({class: "flex flex-grow"}),
        () => !questionsDropdownVisible.val ? span(
          {class: "material-symbols-outlined"},
          "expand_circle_down"
        ) : "",
        () => questionsDropdownVisible.val ? span(
          {class: "material-symbols-outlined"},
          "expand_circle_up"
        ) : "",
      )
    ),
    div({class: "h-1"}),
    div(
      {class: "position: relative"},
      () => {
        if (!questionsDropdownVisible.val){
          return ""
        }
        let questionsDropdownContents = div({class: "p-2 text-dark bg-light z-10 flex flex-col absolute w-full border-2 border-steel rounded-lg max-h-[50vh] overflow-y-auto overflow-x-hidden"});
        for (let x = 0; x < options.length; ++x){
          van.add(
            questionsDropdownContents,
            button(
              {class: `font-rope text-sm sm:text-md p-1 w-full rounded-lg hover:bg-steel`, 
                onclick: ()=>{
                  questionsDropdownVisible.val = false;
                  onChosen(x);
                }
              },
              `${options[x]}`
            )
          )
        }
        return questionsDropdownContents;
      },
    )
  )
}

export {Picker}