import van from "vanjs-core"
import { formatElapsedTimeForCasing } from "../helpers/formatting";

const {div, button, t} = van.tags;


const TranscriptModal = ({problemTitle,  transcriptData, onCancel}) => {

  let chatOutputEl = div({class: "border-dark border-l-4 sm:pl-10 sm:pr-10 pl-2 pr-2 max-h-[500px] overflow-y-auto font-rope"});

  const addMessageToChat = (heading, msg, marking="") => {
    van.add(
      chatOutputEl,
      t(
        {class: "font-semibold font-rope"},
        heading
      ),
      div(
        {class: "flex w-full"},
        t(
          {class: "pb-[10px]"},
          msg
        ),
        marking == "" ? null : div(
          {class: "flex-grow"}
        ),
        marking == "" ? null : t(
          {class: "font-semibold min-w-14 text-base text-end"},
          marking
        ),
      ),
      div(
        {class: "flex w-full font-rope"},
        div({class: "flex-grow"})
      ),
    );
  }

  console.log("Transcript length " + transcriptData?.transcript?.length);
  for (let i = 0; i < transcriptData?.transcript?.length ?? 0; ++i){
    let item = transcriptData?.transcript[i] ?? {};
    addMessageToChat(
      i % 2 == 0 ? "You" : "Interviewer",
      item.replace(/<CANDIDATE>:/g, "").replace(/<INTERVIEWER>:/g, "")  ?? "",
       i % 2 == 0 ? formatElapsedTimeForCasing(transcriptData?.responseTimes[i / 2] ?? 0) : ""
    )
  }

  return div(
    {class: "modal w-full h-full flex justify-center items-center z-10 absolute top-0 left-0 bg-dark bg-opacity-50", style: "backdrop-filter: blur(10px);"},
    div({class: "w-[90%]  flex flex-col p-5 bg-light border-french border-8 rounded-xl slide-in-elliptic-top-fwd"},
      t(
        {class: "font-rope font-semibold text-xl text-dark"},
        problemTitle,
      ),
      div({class: "h-4"}),
      t(
        {class: "font-rope text-dark"},
        "Transcript",
      ),
      div({class: "h-4"}),
      chatOutputEl,
      div({class: "flex"},
        div({class: "flex flex-grow"}),
        button(
          {
            class: "p-2 max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope  text-white hover:text-dark bg-dark hover:bg-gold", 
            onclick: onCancel
          },
          "Close",
        ),
      ),
    ),
  )
}

export {TranscriptModal}